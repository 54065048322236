import config from "../../config/config";
import store from "../../store/store";
import ls from "local-storage";
import _ from 'lodash';

const base_url = config.base_url;
store.updateTenantId(ls.get("tenant_id"));

export default class menuAPI {
    static getMenus(params) {
        let paramString = _.reduce(params, (result, value, key) => {
            return `${ result }${ result === '?' ? '' : '&' }${ key }=${ value }`;
        }, '?');
        let url = `${ base_url }tenants/${ store.tenantId }/menus/${ paramString }&annotated=1`;
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: null
            })
                .then(raw_response => raw_response.json())
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    console.log(err);
                    reject(err);
                });
        });
    }

    static async getRecursiveMenus(params, data) {
        store.updateLoading(true);
        let response = await this.getMenus(params);
        let new_data = data;
        if (response['has_next_page']) {
            params.page++;
            new_data = _.union(new_data, response['data']);
            await this.getRecursiveMenus(params, new_data);
        } else {
            new_data = _.union(new_data, response['data']);
            store.updateMenus(new_data);
        }
        store.updateLoading(false);
        return new_data;
    }

    static getMenuById(menuID) {
        store.updateLoading(true);
        let url = `${ base_url }tenants/${ store.tenantId }/menus/${ menuID }/`;
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: null
            })
                .then(raw_response => raw_response.json())
                .then(response => {
                    store.updateLoading(false);
                    resolve(response.data);
                })
                .catch(err => {
                    store.updateLoading(false);
                    console.log(err);
                    reject(err);
                });
        });
    }

    static searchMenus(searchQuery, page) {
        store.updateLoading(true);
        let url = `${ base_url }tenants/${ store.tenantId }/menus/?annotated=1&search=${ searchQuery }`;
        if (page) {
            url = `${ url }&page=${ page }`;
        }
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: null
            })
                .then(raw_response => raw_response.json())
                .then(response => {
                    let data = response.data;
                    store.updateMenus(data);
                    store.updateLoading(false);
                    resolve(response);
                })
                .catch(err => {
                    console.log(err);
                    store.updateLoading(false);
                    reject(err);
                });
        });
    }

    static postMenu(data) {
        store.updateLoading(true);
        return new Promise((resolve, reject) => {
            fetch(base_url + "tenants/" + store.tenantId + "/menus/", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Token " + store.token
                },
                body: data
            })
                .then(raw_response => raw_response.json())
                .then(response => {
                    store.updateLoading(false);
                    resolve(response);
                })
                .catch(err => {
                    store.updateLoading(false);
                    console.log(err);
                    reject(err);
                });
        });
    }

    static updateMenu(menuId, data) {
        store.updateLoading(true);
        return new Promise((resolve, reject) => {
            fetch(base_url + "tenants/" + store.tenantId + "/menus/" + menuId + "/", {
                method: "PATCH",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Token " + store.token
                },
                body: data
            })
                .then(raw_response => raw_response.json())
                .then(response => {
                    store.updateLoading(false);
                    resolve(response.data);
                })
                .catch(err => {
                    store.updateLoading(false);
                    console.log(err);
                    reject(err);
                });
        });
    }

    static deleteMenu(menuId) {
        store.updateLoading(true);
        const url = `${ base_url }tenants/${ store.tenantId }/menus/${ menuId }/`;
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: "DELETE",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Token " + store.token
                },
                body: null
            })
                .then(raw_response => raw_response.text())
                .then(response => {
                    store.updateLoading(false);
                    resolve(response);
                })
                .catch(err => {
                    store.updateLoading(false);
                    console.log(err);
                    reject(err);
                });
        });
    }

    static getCategories(params) {
        let paramString = _.reduce(params, (result, value, key) => {
            return `${ result }${ result === '?' ? '' : '&' }${ key }=${ value }`;
        }, '?');
        let url = `${ base_url }tenants/${ store.tenantId }/categories/${ paramString }&expanded=1`;
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: null
            })
                .then(raw_response => raw_response.json())
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    console.log(err);
                    reject(err);
                });
        });
    }

    static async getRecursiveCategories(params, data) {
        store.updateLoading(true);
        let response = await this.getCategories(params);
        let new_data = data;
        if (response['has_next_page']) {
            params.page++;
            new_data = _.union(new_data, response['data']);
            await this.getRecursiveCategories(params, new_data);
        } else {
            new_data = _.union(new_data, response['data']);
            if (!params.menus) {
                store.updateCategories(new_data);
            }
        }
        store.updateLoading(false);
        return new_data;
    }

    static updateCategory(category_id, data) {
        store.updateLoading(true);
        let url = `${ base_url }tenants/${ store.tenantId }/categories/${ category_id }/?expanded=1`;
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: "PATCH",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Token " + store.token
                },
                body: data
            })
                .then(raw_response => raw_response.json())
                .then(response => {
                    store.updateCategory(response.data);
                    store.updateLoading(false);
                    resolve(response);
                })
                .catch(err => {
                    store.updateLoading(false);
                    console.log(err);
                    reject(err);
                });
        });
    }

    static updateCategoryImage(category_id, data) {
        store.updateLoading(true);
        let url = `${ base_url }tenants/${ store.tenantId }/categories/${ category_id }/?expanded=1`;
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: "PATCH",
                headers: {
                    Authorization: "Token " + store.token
                },
                body: data
            })
                .then(raw_response => raw_response.json())
                .then(response => {
                    store.updateCategory(response.data);
                    store.updateLoading(false);
                    resolve(response);
                })
                .catch(err => {
                    store.updateLoading(false);
                    console.log(err);
                    reject(err);
                });
        });
    }

    static getItems(params) {
        let paramString = _.reduce(params, (result, value, key) => {
            return `${ result }${ result === '?' ? '' : '&' }${ key }=${ value }`;
        }, '?');
        let url = `${ base_url }tenants/${ store.tenantId }/items/${ paramString }`;
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: null
            })
                .then(raw_response => raw_response.json())
                .then(response => {
                    let data = response.data;
                    store.updateItems(data);
                    resolve(response);
                })
                .catch(err => {
                    console.log(err);
                    reject(err);
                });
        });
    }

    static async getRecursiveItems(params, data) {
        store.updateLoading(true);
        let response = await this.getItems(params);
        let new_data = data;
        if (response['has_next_page'] === true) {
            params.page++;
            new_data = _.union(new_data, response['data']);
            await this.getRecursiveItems(params, new_data);
        } else {
            new_data = _.union(new_data, response['data']);
            store.updateItems(new_data);
        }
        store.updateLoading(false);
        return new_data;
    }

    static updateItemImage(item_id, data) {
        store.updateLoading(true);
        let url = `${ base_url }tenants/${ store.tenantId }/items/${ item_id }/?expanded=1`;
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: "PATCH",
                headers: {
                    Accept: "application/json",
                    Authorization: "Token " + store.token
                },
                body: data
            })
                .then(raw_response => raw_response.json())
                .then(response => {
                    let data = response.data;
                    store.updateItem(data);
                    store.updateLoading(false);
                    resolve(data);
                })
                .catch(err => {
                    store.updateLoading(false);
                    console.log(err);
                    reject(err);
                });
        });
    }

    static updateItem(item_id, data) {
        store.updateLoading(true);
        let url = `${ base_url }tenants/${ store.tenantId }/items/${ item_id }/?expanded=1`;
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: "PATCH",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Token " + store.token
                },
                body: data
            })
                .then(raw_response => raw_response.json())
                .then(response => {
                    store.updateItem(response.data);
                    store.updateLoading(false);
                    resolve(response.data);
                })
                .catch(err => {
                    store.updateLoading(false);
                    console.log(err);
                    reject(err);
                });
        });
    }

    static getItemById(itemId) {
        store.updateLoading(true);
        let url = `${ base_url }tenants/${ store.tenantId }/items/${ itemId }/?expanded=1`;
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: null
            })
                .then(raw_response => raw_response.json())
                .then(response => {
                    store.updateLoading(false);
                    resolve(response.data);
                })
                .catch(err => {
                    store.updateLoading(false);
                    console.log(err);
                    reject(err);
                });
        });
    }

    static updateVariation(variationId, data) {
        store.updateLoading(true);
        let url = `${ base_url }tenants/${ store.tenantId }/variations/${ variationId }`;
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: "PATCH",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Token " + store.token
                },
                body: data
            })
                .then(raw_response => raw_response.json())
                .then(response => {
                    store.updateLoading(false);
                    resolve(response);
                })
                .catch(err => {
                    store.updateLoading(false);
                    console.log(err);
                    reject(err);
                });
        });
    }

    static getItemsByCategory(categoryId) {
        store.updateLoading(true);
        let url = `${ base_url }tenants/${ store.tenantId }/items/?categories=${ categoryId }`;
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: null
            })
                .then(raw_response => raw_response.json())
                .then(response => {
                    let data = response.data;
                    store.updateItems(data);
                    store.updateLoading(false);
                    resolve(response);
                })
                .catch(err => {
                    console.log(err);
                    store.updateLoading(false);
                    reject(err);
                });
        });
    }

    static searchItems(searchQuery, page) {
        store.updateLoading(true);
        let url = `${ base_url }tenants/${ store.tenantId }/items/?search=${ searchQuery }`;

        if (page) {
            url = `${ url }&page=${ page }`;
        }
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: null
            })
                .then(raw_response => raw_response.json())
                .then(response => {
                    let data = response.data;
                    store.updateItems(data);
                    store.updateLoading(false);
                    resolve(response);
                })
                .catch(err => {
                    console.log(err);
                    store.updateLoading(false);
                    reject(err);
                });
        });
    }

    static searchCategories(searchQuery, page) {
        store.updateLoading(true);
        let url = `${ base_url }tenants/${ store.tenantId }/categories/?search=${ searchQuery }`;
        if (page) {
            url = `${ url }&page=${ page }`;
        }
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: null
            })
                .then(raw_response => raw_response.json())
                .then(response => {
                    let data = response.data;
                    store.updateCategories(data);
                    store.updateLoading(false);
                    resolve(response);
                })
                .catch(err => {
                    console.log(err);
                    store.updateLoading(false);
                    reject(err);
                });
        });
    }

    static async getRecursiveModifierLists(params, data) {
        let response = await this.getModifierLists(params);
        let new_data = data;
        if (response['has_next_page'] === true) {
            params.page++;
            new_data = _.union(new_data, response['data']);
            await this.getRecursiveModifierLists(params, new_data);
        } else {
            new_data = _.union(new_data, response['data']);
            store.updateModifierLists(new_data);
            store.updateLoading(false);
        }
        store.updateLoading(false);
        return new_data;
    }

    static getModifierLists(params) {
        store.updateLoading(true);
        let paramString = _.reduce(params, (result, value, key) => {
            return `${ result }${ result === '?' ? '' : '&' }${ key }=${ value }`;
        }, '?');
        let url = `${ base_url }tenants/${ store.tenantId }/modifier-lists/${ paramString }&expanded=1`;
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: null
            })
                .then(raw_response => raw_response.json())
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    console.log(err);
                    reject(err);
                });
        });
    }
    static searchModifierLists(searchQuery, page) {
        store.updateLoading(true);
        let url = `${ base_url }tenants/${ store.tenantId }/modifier-lists/?expanded=1&search=${ searchQuery }`;
        if (page) {
            url = `${ url }&page=${ page }`;
        }
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: null
            })
                .then(raw_response => raw_response.json())
                .then(response => {
                    let data = response.data;
                    store.updateModifierLists(data);
                    store.updateLoading(false);
                    resolve(response);
                })
                .catch(err => {
                    console.log(err);
                    store.updateLoading(false);
                    reject(err);
                });
        });
    }

    static updateModifierList(modifierListId, data) {
        store.updateLoading(true);
        let url = `${ base_url }tenants/${ store.tenantId }/modifier-lists/${ modifierListId }/`;
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: "PATCH",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Token " + store.token
                },
                body: data
            })
                .then(raw_response => raw_response.json())
                .then(response => {
                    store.updateLoading(true);
                    this.getRecursiveModifierLists({page: 1}).then(() => {
                        store.updateLoading(false);
                        resolve(response);
                    })
                })
                .catch(err => {
                    console.log(err);
                    store.updateLoading(false);
                    reject(err);
                });
        });
    }

    static async getRecursiveModifiers(params, data) {
        store.updateLoading(true);
        let response = await this.getModifiers(params);
        let new_data = data;
        if (response['has_next_page'] === true) {
            params.page++;
            new_data = _.union(new_data, response['data']);
            await this.getRecursiveModifiers(params, new_data);
        } else {
            new_data = _.union(new_data, response['data']);
            store.updateModifiers(new_data);
        }
        store.updateLoading(false);
        return new_data;
    }

    static getModifiers(params) {
        let paramString = _.reduce(params, (result, value, key) => {
            return `${ result }${ result === '?' ? '' : '&' }${ key }=${ value }`;
        }, '?');
        let url = `${ base_url }tenants/${ store.tenantId }/modifiers/${ paramString }`;
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: null
            })
                .then(raw_response => raw_response.json())
                .then(response => {
                    let data = response.data;
                    store.updateModifiers(data);
                    resolve(response);
                })
                .catch(err => {
                    console.log(err);
                    reject(err);
                });
        });
    }

    static updateModifier(modifierId, data) {
        store.updateLoading(true);
        let url = `${ base_url }tenants/${ store.tenantId }/modifiers/${ modifierId }/`;
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: "PATCH",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Token " + store.token
                },
                body: data
            })
                .then(raw_response => raw_response.json())
                .then(response => {
                    store.updateModifier(response.data);
                    store.updateLoading(false);
                    resolve(response);
                })
                .catch(err => {
                    console.log(err);
                    store.updateLoading(false);
                    reject(err);
                });
        });
    }

    static searchModifiers(searchQuery, page) {
        store.updateLoading(true);
        let url = `${ base_url }tenants/${ store.tenantId }/modifiers/?search=${ searchQuery }`;
        if (page) {
            url = `${ url }&page=${ page }`;
        }
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: null
            })
                .then(raw_response => raw_response.json())
                .then(response => {
                    let data = response.data;
                    store.updateModifiers(data);
                    store.updateLoading(false);
                    resolve(response);
                })
                .catch(err => {
                    console.log(err);
                    store.updateLoading(false);
                    reject(err);
                });
        });
    }

    static bulkUpdate(type, body) {
        store.updateLoading(true);
        let url = `${ base_url }tenants/${ store.tenantId }/${ type }/bulk-update/`;
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "Authorization": "Token " + store.token
                },
                body: body
            })
                .then(raw_response => raw_response.json())
                .then(response => {
                    store.updateLoading(false);
                    resolve(response);
                })
                .catch(err => {
                    store.updateLoading(false);
                    console.log(err);
                    reject(err);
                });
        });
    }


}